/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Switch from 'react-switch'
import * as Yup from 'yup'

import CircularProgress from '@material-ui/core/CircularProgress'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { AiOutlineBank } from 'react-icons/ai'
import { FiUser, FiSmartphone, FiMail } from 'react-icons/fi'
import { MdAccountBalanceWallet, MdSecurity } from 'react-icons/md'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import InputSelect from '../../components/InputSelect'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { ValorMascarar } from '../../utils/masks'

import { BtnModal, BtnVoltar, Container, Content, PaymentInformation } from './styles'
import { UserData, UserDetails, UserAccount, FinancialIndicationData, LegalIndicationData } from '../../utils/interfaces'
import { BiCake } from 'react-icons/bi'
import { Collapse } from '@material-ui/core'
import validaCPF from '../../utils/validaCPF'
import moment from 'moment'
import calculaIdade from '../../utils/calculaIdade'
import InputHidden from '../../components/InputHidden'
import ModalBox from '../../components/Modal'
import { useLeadLovers } from '../../hooks/mectrics'


const ConfirmOwnership: React.FC = () => {
  const leadLovers = useLeadLovers()
  
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userAccount, setUserAccount] = usePersistedState<UserAccount>(
    'userAccount',
    {} as UserAccount,
  )
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [menorIdade] = usePersistedState('menorIdade', false)
  const [respLegal] = usePersistedState('respLegal', false)
  const [respFinanceiro, setRespFinanceiro] = usePersistedState(
    'respFinanceiro',
    false
  )
  const [legalIndicationData] = usePersistedState<LegalIndicationData>(
    'legalIndicationData',
    {} as LegalIndicationData
  )
  const [financialIndicationData, setFinancialIndicationData] = usePersistedState<FinancialIndicationData>(
    'financialIndicationData',
    {} as FinancialIndicationData,
  )
  const [respLegalEqualFin, setRespLegalEqualFin] = usePersistedState(
    'respLegalEqualFin',
    'N',
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [PaymentType, setPaymentType] = useState(userAccount.paymentType ? userAccount.paymentType : '')
  const [authorizeTitularPayment, setAuthorizeTitularPayment] = useState(false)

  const [aguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)
  const [bank, setBank] = useState({
    label: `${userAccount.dcrBanco ? userAccount.dcrBanco : ''}`,
    value: `${userAccount.banco ? userAccount.banco : ''}`,
  })
  const [contribValue, setContribValue] = useState(
    userDetails.contribuicaoBasica
      ? userDetails.contribuicaoBasica
      : 50
  )

  const [dcrContribValue, setDcrContribValue] = useState(
    ValorMascarar(`${contribValue.toFixed(2)}`)
  )
  const history = useHistory()

  const formRef = useRef<FormHandles>(null)

  const toggleAuthorizeTitularPayment = useCallback(() => {
    setAuthorizeTitularPayment(!authorizeTitularPayment)
  }, [authorizeTitularPayment, setAuthorizeTitularPayment])

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .min(3, 'Digite o nome completo')
            .matches(/\s/g, 'Digite o nome completo'),
          paymentDay: Yup.string().when('paymentType', {
            is: 'D',
            then: Yup.string().required('Selecione uma opção')
          }),
          paymentType: Yup.string().required('Selecione uma opção'),
          bank: Yup.string().when('paymentType', {
            is: 'D',
            then: Yup.string().required('Selecione um banco'),
          }),
          agency: Yup.string()
            .max(15)
            .when('paymentType', {
              is: 'D',
              then: Yup.string().required('Informe uma agência'),
            }),
          account: Yup.string().when('paymentType', {
            is: 'D',
            then: Yup.string().required('Informe uma conta'),
          }),
          financialResponsible: Yup.string().when('menorIdade', {
            is: 'N',
            then: Yup.string().required('Informe se possui responsável financeiro')
          }),
          nomeResponsavelFin: Yup.string().when('respFinanceiro', {
            is: 'S',
            then: Yup.string()
              .required('Nome do responsável legal é obrigatório')
              .matches(/\s/g, 'Digite o nome completo'),
          }),
          cpfResponsavelFin: Yup.string().when('respFinanceiro', {
            is: 'S',
            then: Yup.string()
              .required('CPF obrigatório')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(
                    data.cpfResponsavelFin.replaceAll('.', '').replace('-', ''),
                  ) || data.cpfResponsavelFin === '',
              ),
          }),
          birthdateResponsavelFin: Yup.string().when('respFinanceiro', {
            is: 'S',
            then: Yup.string()
              .required('Data de nascimento obrigatória')
              .min(10, 'Data de nascimento deve seguir o formato dd/mm/aaaa.')
              .test(
                '',
                'Data inválida.',
                () =>
                  moment(
                    data.birthdateResponsavelFin.split('/').reverse().join('-'),
                  ).isValid() || data.birthdateResponsavelFin === '',
              )
              .test(
                '',
                'O responsável legal deve ter 18 anos ou mais.',
                () =>
                  calculaIdade(
                    data.birthdateResponsavelFin.split('/').reverse().join('-'),
                  ) >= 18 || data.birthdateResponsavelFin === '',
              )
              .test(
                '',
                'Data de nascimento inválida',
                () =>
                  calculaIdade(
                    data.birthdateResponsavelFin.split('/').reverse().join('-'),
                  ) <= 115 || data.birthdateResponsavelFin === '',
              ),
          }),
          telResponsavelFin: Yup.string().when('respFinanceiro', {
            is: 'S',
            then: Yup.string()
              .max(15, 'Telefone inválido')
              .required('Celular obrigatório'),
          }),
          emailResponsavelFin: Yup.string().when('respFinanceiro', {
            is: 'S',
            then: Yup.string()
              .required('E-mail é obrigatório')
              .email('Digite um e-mail válido'),
          }),
          financialResponsibleEqualLegalResponsible: Yup.string().when('menorIdade', {
            is: 'S',
            then: Yup.string().required('Informe se o responsável financeiro e o responsável legal são a mesma pessoa')
          }),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserData({
          ...userData,
          name: data.name,
        })
        await setUserAccount({
          ...userAccount,
          paymentType: data.paymentType,
          dcrBanco: data.paymentType === 'B' ? '' : bank.label,
          banco: data.paymentType === 'B' ? 0 : parseInt(bank.value, 10),
          conta: data.paymentType === 'B' ? '' : data.account,
          agencia: data.paymentType === 'B' ? 0 : data.agency * 1,
        })
        await setUserDetails({
          ...userDetails,
          contribuicaoBasica: parseFloat(
            data.contribution
              .replaceAll('.', '')
              .replace(',', '.')
              .replace('R$ ', ''),
          ),
          paymentDay: data.paymentType === 'B' ? '' : data.paymentDay,
        })

        if (respLegalEqualFin === 'S' && respFinanceiro) {
          await setFinancialIndicationData({
            ...financialIndicationData,
            nomeResponsavelFin: legalIndicationData.nomeResponsavelLeg,
            cpfResponsavelFin: legalIndicationData.cpfResponsavelLeg,
            birthdateResponsavelFin: legalIndicationData.birthdateResponsavelLeg
              .split('/')
              .reverse()
              .join('-'),
            telResponsavelFin: legalIndicationData.telResponsavelLeg,
            emailResponsavelFin: legalIndicationData.emailResponsavelLeg,
          })
        } else if (respFinanceiro) {
          await setFinancialIndicationData({
            ...financialIndicationData,
            nomeResponsavelFin: data.nomeResponsavelFin,
            cpfResponsavelFin: data.cpfResponsavelFin,
            birthdateResponsavelFin: data.birthdateResponsavelFin
              .split('/')
              .reverse()
              .join('-'),
            telResponsavelFin: data.telResponsavelFin,
            emailResponsavelFin: data.emailResponsavelFin,
          })
        } else {
          await setFinancialIndicationData({
            ...financialIndicationData,
            nomeResponsavelFin: '',
            cpfResponsavelFin: '',
            birthdateResponsavelFin: '',
            telResponsavelFin: '',
            emailResponsavelFin: '',
          })
        }

        formRef.current?.setErrors({})

        history.push('/conclusion')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err as any))
      }
    },
    [
      setUserData,
      userData,
      setUserAccount,
      userAccount,
      bank,
      setUserDetails,
      userDetails,
      history,
      legalIndicationData,
      financialIndicationData,
      respLegalEqualFin,
      respFinanceiro,
      PaymentType
    ],
  )

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handlepayment = useCallback(async option => {
    setPaymentType(option.value)
  }, [])

  const handleChangeBanco = useCallback(e => {
    const t = e
    setBank(t)
  }, [])

  const handleChangeFinancialResponsible = useCallback((e) => {
    setRespFinanceiro(e.value === '2')
    if (e.value === '1')
      return setFinancialIndicationData({
        ...financialIndicationData,
        nomeResponsavelFin: '',
        cpfResponsavelFin: '',
        birthdateResponsavelFin: '',
        telResponsavelFin: '',
        emailResponsavelFin: '',
      })
  }, [])

  const handleChangeFinancialResponsibleEqualLegalResponsible = useCallback((e) => {
    setRespLegalEqualFin(e.value)

    if (e.value === 'S')
      return setFinancialIndicationData({
        ...financialIndicationData,
        nomeResponsavelFin: legalIndicationData.nomeResponsavelLeg,
        cpfResponsavelFin: legalIndicationData.cpfResponsavelLeg,
        birthdateResponsavelFin: legalIndicationData.birthdateResponsavelLeg,
        telResponsavelFin: legalIndicationData.telResponsavelLeg,
        emailResponsavelFin: legalIndicationData.emailResponsavelLeg,
      })

    return setFinancialIndicationData({
      ...financialIndicationData,
      nomeResponsavelFin: '',
      cpfResponsavelFin: '',
      birthdateResponsavelFin: '',
      telResponsavelFin: '',
      emailResponsavelFin: '',
    })
  }, [
    legalIndicationData,
    financialIndicationData
  ])

  const mudarContrib = useCallback(
    valor => {
      const v = valor.replaceAll('.', '').replace(',', '.').replace('R$ ', '')
      setContribValue(parseFloat(v))
      setDcrContribValue(ValorMascarar(valor))
    },
    [],
  )

  const validarContrib = useCallback(
    valor => {
      const v = valor.replaceAll('.', '').replace(',', '.').replace('R$ ', '')
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(v)) {
        setContribValue(0)
        setDcrContribValue('0,00')
        return
      }

      if (Number(v) < 50) {
        setIsModalOpen(true)
        setContribValue(parseFloat('50.00'))
        setDcrContribValue('50,00')
        return
      }

      setContribValue(parseFloat(v))
      setDcrContribValue(ValorMascarar(valor))
    },
    [],
  )
  useEffect(() => {
    const leadData =  {
      Email: userData.email,
      Notes:'Última página: Definição das contribuições',
    }

    leadLovers.put(leadData)

  }, [])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: userData.name,
            contribution: ValorMascarar(
              userDetails.contribuicaoBasica.toFixed(2).toString(),
            ),
            agency: userAccount.agencia,
            account: userAccount.conta,
            nomeResponsavelFin: financialIndicationData.nomeResponsavelFin,
            cpfResponsavelFin: financialIndicationData.cpfResponsavelFin,
            birthdateResponsavelFin:
              financialIndicationData.birthdateResponsavelFin
                ? financialIndicationData.birthdateResponsavelFin
                  .split('-')
                  .reverse()
                  .join('/')
                : '',
            telResponsavelFin: financialIndicationData.telResponsavelFin,
            emailResponsavelFin: financialIndicationData.emailResponsavelFin,
          }}
        >
          <Content>
            <strong>Definição das Contribuições ao Plano:</strong>
            <InputHidden name='respFinanceiro' value={respFinanceiro ? 'S' : 'N'} type='hidden' />
            <InputHidden name='menorIdade' value={menorIdade ? 'S' : 'N'} type='hidden' />

            <Input placeholder="Nome completo" name="name" icon={FiUser} />
            <Input
              type="text"
              prefix="R$"
              name="contribution"
              mask="currency"
              icon={FaRegMoneyBillAlt}
              placeholder="Aporte mensal"
              value={`${ValorMascarar(dcrContribValue)}`}
              onChange={e => mudarContrib(e.target.value)}
              onBlur={e => validarContrib(e.target.value)}
              disabled={aguarde}
            />
            <Collapse in={!menorIdade}>
              <InputSelect
                name='financialResponsible'
                defaultValue={{
                  label: respFinanceiro ? 'Responsável Financeiro' : 'O Próprio',
                  value: respFinanceiro ? '2' : '1'
                }}
                options={[
                  { label: 'O Próprio', value: '1' },
                  { label: 'Responsável Financeiro', value: '2' }
                ]}
                placeholder='Responsável financeiro'
                onChange={e => handleChangeFinancialResponsible(e)}
              />
            </Collapse>
            <Collapse in={respLegal && respFinanceiro}>
              <InputSelect
                name='financialResponsibleEqualLegalResponsible'
                defaultValue={{
                  label: respLegalEqualFin === 'S' ? 'Sim' : 'Não',
                  value: respLegalEqualFin === 'S' ? 'S' : 'N',
                }}
                options={[
                  { label: 'Sim', value: 'S' },
                  { label: 'Não', value: 'N' }
                ]}
                placeholder='Responsável financeiro e responsável legal são a mesma pessoa?'
                onChange={e => handleChangeFinancialResponsibleEqualLegalResponsible(e)}
              />
            </Collapse>
            <Collapse in={respFinanceiro && respLegalEqualFin === 'N'}>
              <Input
                placeholder="Nome do responsável financeiro"
                name="nomeResponsavelFin"
                icon={FiUser}
                onChange={e => {
                  setFinancialIndicationData({
                    ...financialIndicationData,
                    nomeResponsavelFin: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="CPF do responsável financeiro"
                name="cpfResponsavelFin"
                icon={MdSecurity}
                mask="cpf"
                maxLength={14}
                onChange={e => {
                  setFinancialIndicationData({
                    ...financialIndicationData,
                    cpfResponsavelFin: e.target.value,
                  })
                }}
              />
              <Input
                icon={BiCake}
                name="birthdateResponsavelFin"
                mask="date"
                placeholder="Data de nascimento do responsável financeiro"
                maxLength={10}
                onChange={e => {
                  setFinancialIndicationData({
                    ...financialIndicationData,
                    birthdateResponsavelFin: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="Telefone do responsável financeiro"
                name="telResponsavelFin"
                mask="phone"
                icon={FiSmartphone}
                onChange={e => {
                  setFinancialIndicationData({
                    ...financialIndicationData,
                    telResponsavelFin: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="E-mail do responsável financeiro"
                name="emailResponsavelFin"
                type="mail"
                icon={FiMail}
                onChange={e => {
                  setFinancialIndicationData({
                    ...financialIndicationData,
                    emailResponsavelFin: e.target.value,
                  })
                }}
              />
            </Collapse>
            <div>
              <InputSelect
                name="paymentType"
                defaultValue={{
                  label:
                    PaymentType === 'D'
                      ? 'Débito em conta'
                      : 'Boleto',
                  value: PaymentType === 'D' ? 'D' : 'B',
                }}
                options={[
                  { label: 'Boleto', value: 'B' },
                  { label: 'Débito em conta', value: 'D' },
                ]}
                placeholder="Forma de pagamento da contribuição"
                onChange={option => {
                  handlepayment(option)
                }} />
              <PaymentInformation
                visible={PaymentType === 'D'}>
                Opção de débito em conta está disponível para o Banco do Brasil
              </PaymentInformation>
            </div>

            {PaymentType === 'D' ? (
              <div>
                <InputSelect
                  name="paymentDay"
                  defaultValue={{ label: '5° dia útil', value: 5 }}
                  options={[
                    { label: '5° dia útil', value: 5 },
                  ]}
                  placeholder="Data de pagamento"
                />
                <InputSelect
                  name="bank"
                  defaultValue={{ label: bank.label, value: bank.value }}
                  options={[
                    { label: 'Banco do Brasil', value: '1' },
                  ]}
                  placeholder="Banco"
                  onChange={e => handleChangeBanco(e)}
                />
                <Input
                  name="agency"
                  placeholder="Agência (sem dígito)"
                  type="text"
                  maxLength={5}
                  icon={AiOutlineBank}
                />
                <Input
                  name="account"
                  placeholder="Conta com dígito"
                  mask="conta"
                  maxLength={20}
                  icon={MdAccountBalanceWallet}
                />
              </div>
            ) : (
              <div />
            )}

            <div className="authorization">
              <span>
                {PaymentType === 'D'
                  ? 'Caso escolha por débito em conta, você precisará autorizar junto ao seu banco'
                  : 'Autorizo a cobrança mensal recorrente do valor simulado'}
              </span>
              <Switch
                onChange={toggleAuthorizeTitularPayment}
                checked={authorizeTitularPayment}
                checkedIcon={false}
                uncheckedIcon={false}
                height={22}
                width={44}
                handleDiameter={18}
                offColor="#DEE3E1"
                offHandleColor="#2F80ED"
                onHandleColor="#fff"
                onColor="#2F80ED"
              />
            </div>
          </Content>
        </Form>

        <Button
          type="button"
          color="blue"
          disabled={!authorizeTitularPayment || !reSend}
          onClick={() => formRef.current?.submitForm()}
        >
          {!aguarde ? (
            !reSend ? (
              <span>
                Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
              </span>
            ) : <span>Avançar</span>
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/ppe-fatca')}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <div>
            <p>O aporte mensal não pode ser menor que R$ 50,00.</p>
          </div>
          <div>
            <BtnModal isActive onClick={handleCloseModal}>
              Ok
            </BtnModal>
          </div>
        </ModalBox>
      </Container>
    </>
  )
}

export default ConfirmOwnership
