import 'dotenv/config'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import ModalBox from './components/Modal'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  ,
  document.getElementById('root'),
)
