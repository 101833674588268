/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react'
import { UserData, UserAddress, UserDetails } from '../../utils/interfaces'
import { Container, Content, Confirmation, BtnVoltar } from './styles'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Switch from 'react-switch'

import { FiCheck } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import usePersistedState from '../../hooks/usePersistedState'

const Conclusion: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails] = usePersistedState<UserDetails>('userDetails', {} as UserDetails)
  const [userAddress] = usePersistedState<UserAddress>('userAddress', {} as UserAddress)
  const [acceptTerms2, setAcceptTerms2] = usePersistedState('acceptTerms2', false)

  const [abrappTerm] = useState(getTerm(userDetails.filiacao))

  const history = useHistory()

  function getTerm(option: string) {
    switch (option) {
      case 'F':
        return (
          <>
            <strong>Pedido de filiação - Associado especial previdenciário pessoa física - Família</strong>

            <div>
              <p>
                Eu <b>{userData.name}</b>, portador(a) da Cédula de Identidade RG nº <b>{userData.rg ?? ''}</b>,
                inscrito(a) no CPF/MF sob o <b>{userData.cpf}</b>, residente e domiciliado{' '}
                <b>{userAddress.logradouro} {userAddress.numero}, {userAddress.complemento ? `${userAddress.complemento}, ` : ''}
                  {userAddress.bairro} {userAddress.cep}, {userAddress.municipio}/{userAddress.uf}</b>, por meio do presente venho requerer minha filiação
                ao quadro associativo da ABRAPP na qualidade de <b>Associado Especial Previdenciário Pessoa Física - Família da ABRAPP</b>,
                exclusivamente para fins de inscrição ao Plano de Benefícios Setorial (FamíliaPrev) oferecido por (Instituto de Previdência Complementar- Cibrius).
              </p>
              <p>
                Ao assinar o presente, declaro estar ciente do inteiro teor do Estatuto Social da ABRAPP, bem como dos direitos e deveres impostos aos seus membros.
              </p>
              <p>
                Autorizo que os dados pessoais aqui informados sejam utilizados para fins de filiação à ABRAPP e que os mesmos serão
                protegidos atendendo ao disposto na Lei nº 13.709/2018.
              </p>
            </div>
          </>
        )
      case 'V':
        return (
          <>
            <strong>Pedido de filiação - Associado especial previdenciário pessoa física - Vinculado</strong>

            <div>
              <p>
                À<br />
                Diretoria Integrada do Sistema ABRAPP
              </p>
              <br />
              <p>
                Eu <b>{userData.name}</b>, portador(a) da Cédula de Identidade RG nº <b>{userData.rg ?? ''}</b>,
                inscrito(a) no CPF/MF sob o <b>{userData.cpf}</b>, residente e domiciliado{' '}
                <b>{userAddress.logradouro} {userAddress.numero}, {userAddress.complemento ? `${userAddress.complemento}, ` : ''}
                  {userAddress.bairro} {userAddress.cep}, {userAddress.municipio}/{userAddress.uf}</b>, por meio do presente venho requerer minha filiação
                ao quadro associativo da ABRAPP na qualidade de <b>Associado Especial Previdenciário Pessoa Física - Vinculado da ABRAPP</b>,
                exclusivamente para fins de inscrição ao Plano de Benefícios Setorial (FamíliaPrev) oferecido por (Instituto de Previdência Complementar- Cibrius).
              </p>
              <p>
                Ao assinar o presente, declaro estar ciente do inteiro teor do Estatuto Social da ABRAPP, bem como dos direitos e deveres impostos aos
                seus membros, bem como declaro, para todos os fins e sob as penas da lei, ser segurado do Regime Geral de Previdência Social - RGPS
                nesta data, ciente de que a falsa declaração resultará na exclusão do quadro associativo.
              </p>
              <p>
                Autorizo que os dados pessoais aqui informados sejam utilizados para fins de filiação à ABRAPP e que os mesmos
                serão protegidos atendendo ao disposto na Lei nº 13.709/2018.
              </p>
            </div>
          </>
        )
      case 'PJ':
        return (
          <>
            <strong>Termo de associação - Associado especial previdenciário pessoa jurídica</strong>

            <div>
              <p>
                À<br />
                Diretoria Integrada do Sistema ABRAPP
              </p>
              <br />
              <p>
                A/O <b>{userData.name}</b>, inscrita no CNPJ/MF sob nº <b>{userData.cnpj ?? ''}</b>,
                com sede à <b>{userAddress.logradouro} {userAddress.numero}, {userAddress.complemento ? `${userAddress.complemento}, ` : ''}
                  {userAddress.bairro} {userAddress.cep}, {userAddress.municipio}/{userAddress.uf}</b>, representada na forma de seus atos
                constitutivos,  por meio do presente venho requerer minha filiação ao quadro associativo da ABRAPP na qualidade de{' '}
                <b>Associado Especial Previdenciário Pessoa Jurídica da ABRAPP</b>, exclusivamente para fins de oferta
                do Plano de Benefícios Setorial (FamíliaPrev) oferecido por (Instituto de Previdência Complementar- Cibrius),
                às pessoas físicas a mim vinculadas (empregados, sócios, administradores e diretores), sendo
                vedada a inscrição de terceiros estranhos ao quadro funcional.
              </p>
              <p>
                Declaro, para todos os fins e sob as penas da lei:
                <ul>
                  <li>{'a)'} ter os poderes necessários para representação da pessoa jurídica ora signatária;</li>
                  <li>{'b)'} estar ciente do inteiro teor do Estatuto Social da ABRAPP, bem como dos direitos e deveres impostos aos seus membros;</li>
                  <li>{'c)'} estar regulamente constituído em território nacional e com registro ativo, sob pena de exclusão do quadro associativo; e</li>
                  <li>{'d)'} estar ciente de que a possibilidade de efetuar contribuições previdenciárias, em favor dos participantes vinculados ao
                    PLANO, estará condicionada à prévia celebração de instrumento contratual específico com a (Instituto de Previdência Complementar- Cibrius).</li>
                </ul>
              </p>
            </div>
          </>
        )
      default:
        return <></>
    }
  }

  const handleConfirmaAdesao = useCallback(async () => {
    history.push('/resume')
  }, [])

  const toggleAcceptTerms2 = useCallback(() => {
    setAcceptTerms2(!acceptTerms2)
  }, [acceptTerms2, setAcceptTerms2])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Confirmation>
            {abrappTerm}

            <strong>Da responsabilidade do participante:</strong>
            <div>
              <p>
                Eu <b>{userData.name}</b>, inscrito no CPF <b>{userData.cpf}</b>
                , declaro estar de inteira responsabilidade pelas informações
                realizadas, estando ciente de que a falsidade nas informações
                prestadas configura crime previsto no Código Penal Brasileiro,
                passível de apuração na forma da Lei, bem como poderá ser
                enquadrada como Litigância de Má Fé.
                <Switch
                  onChange={toggleAcceptTerms2}
                  checked={acceptTerms2}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={40}
                  handleDiameter={16}
                  offColor="#DEE3E1"
                  offHandleColor="#2F80ED"
                  onHandleColor="#fff"
                  onColor="#2F80ED"
                />
              </p>
            </div>
          </Confirmation>
        </Content>

        <Button
          type="button"
          color="blue"
          width="large"
          disabled={!acceptTerms2}
          onClick={handleConfirmaAdesao}
        >
          <span>Avançar</span>
        </Button>

        <BtnVoltar
          type="button"
          onClick={() => history.push('/confirm-ownership')}
        >
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Conclusion
