import styled from 'styled-components';
import { transparentize } from 'polished';

interface GoalItemProps {
  isSelected: boolean
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin-bottom: 10px;
  text-align: center;

  .app_title {
    display: flex;
    width: 100%;
    justify-items: center;

    strong {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: ${props => props.theme.colors.text};

      display: flex;
      justify-content: center;
      margin: 0 0 15px 22.5px;
    }

    svg {
      width: 22.5px;
      height: 22.5px;
      color: ${({ theme }) => theme.colors.mainColor};
      cursor: pointer;
    }
  }

  span {
    margin-bottom: 15px;
  }

  section {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 2rem;
  }

  footer {
    font-size: 11px;
    width: 100%;

    .confirm-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;

      span {
        margin-right: 10px;
        margin-bottom: 0;
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const TaxationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-evenly;
  gap: 12px;
  width: 100%;
  margin: 20px 0;
`

export const GoalItem = styled.button<GoalItemProps>`
  width: 220px;
  height: 60px;
  padding: 8px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid ${props => props.isSelected
    ? transparentize(0.4, props.theme.colors.mainColor)
    : transparentize( 0.4, '#AEAEAE')};

  font-weight: ${props => props.isSelected
    ? 'bold'
    : 'normal'};
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const Line = styled.div`
  width: 80%;
  height: 1px;
  margin: 15px auto 20px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`
