export default function clientConfig() {
  const config = {
    codCliente: 2170, //2170-aspprevefpc_cibrius_prd,
                      //9170-aspprevefpc_cibrius_hmg
    plano: 4, // 2 = plano conabprev / 4 = plano família
    tipo: 1,
  }

  return config
}
