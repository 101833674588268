import { transparentize } from 'polished';
import styled from 'styled-components';

interface PaymentInformationProps {
  visible: boolean;
}

interface BtnModalProps {
  isActive: boolean;
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  
  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;
  
  form {
    max-width: 512px;

    .input {
      margin-top: 12px;
    }
  }

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  strong {
    text-align: center;
    font-size: 18px;
    color: #0071BA;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  > form > div.accept {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 25px;
    text-align: right;

    small {
      margin-right: 12px;
      font-weight: bold;
    }

    a {
      text-decoration: none;
    }
  }
  > small {
    display: flex;
    width: 70%;
    margin: 5px auto 0;
    font-size: 11px;
    font-style: italic;
    text-align: center;
  }

  div.authorization{
    margin-top: 12px;

    > span {
      vertical-align: super;
      margin-right: 5px;
    }
  }
  @media screen and (max-width: 766px) {
    padding: 20px 15px;

    > small {
      width: 90%;
    }
  }
`

export const PaymentInformation = styled.span<PaymentInformationProps>`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 12px;
  background-color: ${transparentize(0.7, '#F9E900')};
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const InfoBox = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  overflow: none;
  margin-bottom: 10px;
  padding: 5px 12px 8px;

  background: #FFF;
  border-radius: 4px;
  border: 2px solid #f4f6f5;
  box-shadow: 0px 2px 4px rgba(61, 69, 67, 0.05);

  > div.placeholder {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    font-size: 14px;

    small {
      letter-spacing: 0.1em;
      font-weight: 550;
      margin: 4px 0;
      // color: red;
    }

    svg {
      margin-left: 5px;
      color: red;
      cursor: pointer;
      position: relative;
    }
  }

  .inputReg {
    padding: 0;
    border: none;
  }
`

export const BtnModal = styled.button<BtnModalProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #537FE6, #4EB0FC)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #537FE6'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;
  margin: 20px 20px 0 0;

  &:focus {
    text-decoration: underline;
  }
`
