import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import Axios from 'axios'
import { FiMapPin, FiHome, FiMap } from 'react-icons/fi'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { BiMapPin, BiBuildings } from 'react-icons/bi'
import { RiCommunityLine } from 'react-icons/ri'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { UserAddress, UserData } from '../../utils/interfaces'
import { BtnVoltar, Container, Content, BtnModal } from './styles'
import ModalBox from '../../components/Modal'
import { useLeadLovers } from '../../hooks/mectrics'

const AddressInfos: React.FC = () => {
  const [userAddress, setUserAddress] = usePersistedState<UserAddress>(
    'userAddress',
    {} as UserAddress,
  )
  const leadLovers = useLeadLovers()
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const [, setFlagAddress] = useState(
    !(
      userAddress.cep === undefined ||
      userAddress.cep === ' ' ||
      userAddress.cep === ''
    ),
  )
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          cep: Yup.string()
            .min(9, 'CEP inválido')
            .max(9, 'CEP inválido')
            .required('CEP obrigatório'),
          logradouro: Yup.string().required('Logradouro é obrigatório'),
          bairro: Yup.string().required('Bairro é obrigatório'),
          municipio: Yup.string().required('Município é obrigatório'),
          numero: Yup.string()
            .matches(/^[0-9]*$/, 'Número não pode conter letras.')
            .required('Número é obrigatório. Caso não tenha um número digite 0')
            .max(8, 'Limite de 8 caracteres ultrapassado'),
          uf: Yup.string()
            .min(2, 'UF inválida')
            .max(2, 'UF inválida')
            .matches(/^[a-zA-Z]*$/, 'UF inválida')
            .required('UF obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        setUserAddress({
          ...userAddress,
          cep: data.cep,
          logradouro: data.logradouro,
          bairro: data.bairro,
          municipio: data.municipio,
          uf: data.uf,
          numero: data.numero,
          complemento: data.complemento.replaceAll(
            // eslint-disable-next-line no-useless-escape
            /(['"!@#$%¨&*\(\)\:\;])*/g,
            '',
          ),
        })
        history.push('/beneficiary')
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [setUserAddress, userAddress, history],
  )

  const handleBuscaCep = useCallback(
    async cep => {
      const tamanho = cep.length
      if (tamanho === 9) {
        await Axios.get(
          `https://viacep.com.br/ws/${cep.replace('-', '')}/json/`,
        )
          .then(res => {
            if (res.data.erro === true) {
              setFlagAddress(false)
              setUserAddress({
                ...userAddress,
                cep: '',
                bairro: '',
                logradouro: '',
                municipio: '',
                uf: '',
              })
              setTxtModal('CEP inválido')
              setIsModalOpen(true)
            } else {
              setFlagAddress(true)
              setUserAddress({
                ...userAddress,
                cep,
                bairro: res.data.bairro,
                logradouro: res.data.logradouro,
                municipio: res.data.localidade,
                uf: res.data.uf,
              })
              document.location.reload()
            }
          })
          .catch(() => {
            setFlagAddress(false)
            setUserAddress({
              ...userAddress,
              cep: '',
              bairro: '',
              logradouro: '',
              municipio: '',
              uf: '',
            })
            setTxtModal('CEP inválido')
            setIsModalOpen(true)
          })
      }
    },
    [setUserAddress, userAddress],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  // useEffect(() => {
  //   document.getElementById('logradouro').focus()
  // }, [handleBuscaCep])

  useEffect(() => {
    const leadData = {
      Email: userData.email,
      Birthday: userData.birthdate,
      Gender: userData.sexo,
      city: userAddress.municipio,
      state: userAddress.uf,
      Notes: 'Última página: Informações de endereço',
    }

    leadLovers.put(leadData)
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            cep: userAddress.cep,
            numero: userAddress.numero,
            complemento: userAddress.complemento,
          }}
        >
          <Content>
            <strong>Endereço</strong>
            <Input
              name="cep"
              placeholder="CEP"
              mask="cep"
              icon={BiMapPin}
              onChange={e => handleBuscaCep(e.target.value)}
            />
            <Input
              name="logradouro"
              placeholder="Logradouro"
              icon={FiHome}
              value={userAddress.logradouro}
              onChange={e => {
                setUserAddress({
                  ...userAddress,
                  logradouro: e.target.value,
                })
              }}
            />
            <Input
              name="bairro"
              id="bairro"
              placeholder="Bairro"
              icon={RiCommunityLine}
              value={userAddress.bairro}
              onChange={e => {
                setUserAddress({
                  ...userAddress,
                  bairro: e.target.value,
                })
              }}
            />
            <Input
              name="municipio"
              placeholder="Município"
              icon={BiBuildings}
              value={userAddress.municipio}
              onChange={e => {
                setUserAddress({
                  ...userAddress,
                  municipio: e.target.value,
                })
              }}
            />
            <Input
              name="numero"
              placeholder="Número"
              icon={AiOutlineFieldNumber}
              maxLength={8}
            />
            <span className="info">
              Campo &quot;Número&quot; não aceita letras.
            </span>
            <Input
              name="complemento"
              placeholder="Complemento"
              icon={FiMapPin}
            />
            <Input
              name="uf"
              placeholder="UF"
              value={userAddress.uf}
              onChange={e => {
                setUserAddress({
                  ...userAddress,
                  uf: e.target.value.toUpperCase(),
                })
              }}
              maxLength={2}
              icon={FiMap}
            />
          </Content>
        </Form>

        <Button type="submit" color="blue" onClick={handleClick}>
          Avançar
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('personal-infos')}>
          &lt; Anterior
        </BtnVoltar>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default AddressInfos
